import { Component, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  private idToScroll: string;

  @Input()
  public event: any;
  @Input()
  public isMainPage = false;

  constructor() {}

  scroll(id: string): void {
    this.idToScroll = id;
  }

  ngOnInit(): void {
    fromEvent(document, 'shown.bs.modal').subscribe(() => {
      const el: HTMLElement = document.getElementById(this.idToScroll);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

}
